/*
author: shakalya195@gmail.com
*/
body {
    /* background-color: #eefefd; */
    padding: 0px;
    margin: 0px;
}

a {
    color: #8f89f5;
}

::placeholder {
    color: #ccc !important;
}

.faded {
    color: #ccc !important;
}

.s-24 {
    height: 24px;
    width: 24px;
}
.s-32 {
    height: 32px;
    width: 32px;
}

.card-brang-img {
    height: 32px;
}

.p-badge {
    display: inline-block;
    border-radius: 10px !important;
    text-align: center;
    padding: 0 0.5rem !important;
}

.missing {
    color: #fff !important;
    font-size: 10px !important;
    padding: 2px 5px;
    line-height: 13px !important;
    background-color: #f00;
    border-color: #fa5f55;
    font-weight: bold !important;
    border-radius: 3px;
}

.relTop2 {
    position: relative;
    top: -2px;
}

.relTop8 {
    position: relative;
    top: -2px;
}

/* datepicker */
.bs-datepicker-body table td.week span {
    color: #8f89f5 !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover {
    background-color: #8f89f5 !important;
}

.campaign-control-content .title-main {
    font-size: 18px;
    font-weight: 700;
}

.automated-inner {
    position: relative;
}

.automated-inner.disabled * {
    opacity: 0.6;
}

.automated-inner.disabled {
    pointer-events: none;
}

.automated-inner.disabled::after {
    content: "";
    background-image: url(/assets/images/lock.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-position: center;

}

.msg-action-wrap i {
    color: #8e88f4;
}

.messsage-actions .fas.fa-edit {
    color: #000;
}

.dashboard-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
    color: #010101;
    margin-bottom: 15px;
}

.oprationhr {
    padding: 15px;
    background-color: #eefefd;
}

.oprationhr p-calendar,
.oprationhr p-calendar input {
    display: inline-block;
    padding: 6px;
}

.viewsWrapper h4,
.oprationhr h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #010101;
    text-transform: capitalize;
}

.p-carousel-content {
    position: relative;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    z-index: 1;
}

.p-carousel .p-carousel-content .p-carousel-prev.p-disabled,
.p-carousel .p-carousel-content .p-carousel-next.p-disabled {
    display: none;
}

.p-carousel .p-carousel-indicators {
    padding: 0;
}

.full-height-border .p-element {
    /* padding-bottom: 15px; */
}

.p-carousel .p-carousel-content .p-carousel-next {
    right: 0px;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
}

.p-carousel .p-carousel-content .p-carousel-prev:hover,
.p-carousel .p-carousel-content .p-carousel-prev:active,
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:hover,
.p-carousel .p-carousel-content .p-carousel-next:active,
.p-carousel .p-carousel-content .p-carousel-next:focus {
    background-color: transparent !important;
    box-shadow: unset;
    outline: 0 none;
}

.viewsBtn .btn {
    width: 90%;
    max-width: 300px;
}

.views-btn-listing .btn {
    border: 1px solid #E2E9F8;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #0a0a0a;
    width: 100%;
    display: inline-block;
    padding: 3px 10px;
    text-transform: capitalize;
    position: relative;
}

.views-btn-listing .btn span {
    opacity: 0;
    position: absolute;
    left: 10px;
    top: 10px;
}

.oprationhr td {
    /* text-transform: uppercase; */
}

.views-btn-listing .btn.btn-selected span {
    opacity: 1;
}

.views-btn-listing .viewsBtn:first-child .btn {
    background-color: #edffef;
}

.views-btn-listing .viewsBtn:nth-child(2) .btn {
    background-color: #f4f2ff;
}

.views-btn-listing .viewsBtn:nth-child(3) .btn {
    background-color: #fff2ec;
}

.views-btn-listing .viewsBtn:nth-child(4) .btn {
    background-color: #ecfbff;
}

.views-btn-listing .viewsBtn:nth-child(5) .btn {
    background-color: #fcecec;
}

.views-btn-listing .viewsBtn:nth-child(6) .btn {
    background-color: #f4f2ff;
}

.views-btn-listing .viewsBtn:nth-child(7) .btn {
    background-color: #ecfbff;
}

.views-btn-listing .viewsBtn:nth-child(8) .btn {
    background-color: #fff2ec;
}

.views-btn-listing .viewsBtn:nth-child(9) .btn {
    background-color: #f4f2ff;
}

.views-btn-listing .viewsBtn:last-child .btn {
    background-color: #edffef;
}

.internal-logo {
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
}

.internal-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #eee;
}

.oprationhr .btn {
    min-width: 87px;
    display: inline-block;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
}

p-calendar span {
    width: 100%;
}

.emptyPlaceholder {
    text-align: center;
    padding: 25vh 0 25vh 0;
    font-size: 30px;
    background: #f8f8f8;
    color: #ddd;
}

.cursor-pointer {
    cursor: pointer;
}

.chat-content {
    position: relative;
    min-width: 100px;
}

.senderType {
    position: absolute;
    font-size: 10px;
    top: 2px;
    display: none;
}

.chat-content:hover .senderType {
    display: inline-block;
}

.received-msg figure {
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
}

.received-msg figure span {
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
    margin-right: 10px;
    text-transform: capitalize;
}

.icon-brand img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.each-summary {
    overflow: hidden;
}

.modal.show {
    display: block;
}

.optout {
    position: absolute;
    width: 200px;
    background: #ffdada;
    color: #ff0505 !important;
    text-align: center;
    right: -40px;
    top: 15px;
    font-size: 13px;
    font-weight: bold;
    transform: rotate(20deg);
}

.optout .fa {
    margin: 0px 4px;
    color: #ff0505 !important;
}

.addComment {
    margin: 0px 6px;
}

.dropdown-item {
    line-height: 1.2rem;
    color: #9581fb;
    font-size: 14px;
}

.table-common {
    background-color: #eefefd;
}

.table-common tbody tr:hover,
.table-common tbody tr.active {
    background: #fff;
}

.p-link:disabled,
.p-carousel-indicators li:only-child {
    visibility: hidden;
}

.mt3Negative {
    margin-top: -3px;
}

.userRole {
    padding: 2px 4px;
    background: orange;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
}
.form-control,textarea.form-control{
    min-height: 40px;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
}
.form-control:disabled {
    background: #eee !important;
    border: solid thin transparent;
    cursor: not-allowed !important;
    line-height: 26px;
}

.oprationhr input:disabled+.slider {
    opacity: 0.4;
}

.p-datepicker table td.p-datepicker-today>span.p-highlight,
.p-datepicker table td>span.p-highlight {
    color: #fff;
    background: #8e88f4;
}

.dropdown .dropdown-item.disabled {
    cursor: not-allowed;
    pointer-events: none;
}
.p-datepicker table td>span {
    width: 1.5rem;
    height: 1.5rem;
}
/* Summary Modal CSS Start */
.summary-modal .modal-header {
    flex-wrap: wrap;
    padding: 2rem 1rem 1rem 1rem;
}

.summary-modal .modal-header .modal-title {
    width: 100%;
}

.summary-modal .modal-header .order-track {
    margin: 0;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #0A0A0A;
    margin-top: 12px;
}

.summary-modal .modal-header .modal-title {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 16px;
    color: #0A0A0A;
}

.summary-modal .modal-header .btn-close:hover,
.summary-modal .modal-header .btn-close:active,
.summary-modal .modal-header .btn-close:focus {
    outline: 0 none;
    box-shadow: unset;
}

.summary-info p {
    margin: 0;
}

.summary-day p {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #0A0A0A;
    opacity: 0.6;
}

.summary-status .summary-time {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #0A0A0A;
    min-width: 80px;
    position: relative;
    margin-bottom: 0;
}

.order-track {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0a0a0a;
    margin-bottom: 0;
}

.summary-desc {
    position: relative;
    padding-left: 40px;
    margin-left: 40px;
}

.summary-modal .modal-header .btn-close {
    padding: 0;
    position: absolute;
    right: -6px;
    top: -13px;
    background: unset !important;
    margin: 0;
    width: auto;
    height: auto;
    opacity: 1;
}

.summary-info+.summary-info {
    margin-top: 25px;
}

.summary-modal .modal-header .btn-close img {
    height: 20px;
    width: 20px;
}

.summary-status .summary-desc:after {
    position: absolute;
    left: 0;
    content: "";
    height: 98%;
    width: 1px;
    background-color: #D6D6D6;
    top: 0;
}

.summary-desc p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
}

.summary-desc .desc-location {
    font-size: 14px;
    line-height: 16px;
}

.summary-status {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
}

/* Summary Modal CSS Start */
@media screen and (max-width: 767px) {
    .internal-logo {
        height: 40px;
        width: 40px;
    }
}

tbody tr:nth-child(even) {
    /* background-color: #8e88f40a; */
}

.table-responsive {
    /* border-left: 2px solid #EEE; */
    border-radius: 8px;
    padding: 0 10px;
    background-color: #eefefd;
    /* border-right: 2px solid #EEE; */
    /* border-bottom: 2px solid #EEE; */
    overflow: visible;
}

.table-responsive tr td:first-child,
.table-responsive tr th:first-child {
    text-align: center;
}

.table-responsive tbody tr:last-child {
    border-bottom: none;
}

.table-responsive table {
    margin-bottom: 0px;
}

.table-responsive table tr:last-child td {
    border-bottom: none;
}

.btn-form {
    background: #8E88F4;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    border-radius: 6px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    padding: 6px 30px;
    line-height: 26px;
}

.btn-form:hover,
.btn-form:active,
.btn-form:focus {
    background: #453fae;
    color: #fff;
}

.type-grey {
    border-radius: 8px;
    background: #D9D9D9;
    color: #646464;
    font-size: 12px;
    padding: 5px 10px;
    min-width: 88px;
    display: inline-block;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
}

.comingsoon {
    padding: 15% 20%;
    text-align: center;
    background: #eefefd;
    height: 100vh;
}

.comingsoon h1 {
    font-size: 70px;
    color: #aeaaf573;
    font-weight: 700;
    text-shadow: 2px 5px 5px rgb(255 255 255 / 30%);
    border: solid thin;
    padding: 10%;
}

.swal2-input-label {
    text-transform: capitalize;
}

.color-purple {
    color: #8e88f4;
}

.color-red {
    color: #ff0000 !important;
}

.password-icon {
    position: absolute;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
}


.userRole.redbg {
    background-color: #ff0000;
    color: #fff;
}

.userRole.voip {
    background-color: #ff0000;
    color: #fff;
}

.userRole.landline {
    background-color: #ff0000;
    color: #fff;
}

.userRole.invalid {
    background-color: #ff0000;
    color: #fff;
}

.userRole.greybg {
    background-color: #d0d0d0;
    color: #333;
}

.btn-disabled {
    background-color: #eee;
    cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.trackingg {
    color: #8f89f5 !important;
}

.trackingg:hover {
    color: #0a58ca;
    text-decoration: underline;
    cursor: pointer;
}

.btn-primary {
    color: #fff;
    background-color: #8e88f4;
    border-color: #a09cf3;
    font-weight: 600;
}

.btn-primary:hover,.btn.btn-primary:active,.btn.btn-primary:focus {
    color: #fff;
    background-color: #6863d0;
    border-color: #736ecd;
}

.btn-primary:disabled,
.btn-orange:disabled {
    cursor: not-allowed;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #9b95f2;
    border-color: #afabf3;
}
.btn.btn-primary-outline {
    border: 1px solid #8e88f4;
    color: #8e88f4;
}
.btn.btn-primary-outline:hover,
.btn.btn-primary-outline:focus,
.btn.btn-primary-outline:active {
    border: 1px solid #8e88f4;
    color: #FFF;
    background-color: #8e88f4;
    outline: 0 none;
}
.radio-item-content .dropdown-menu.show {
    max-height: 150px;
}
.sweet-alert-container .swal2-input-label {
    justify-content: flex-start;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    opacity: 0.66;
    margin: 0 2rem;
}
.sweet-alert-container textarea {
    background-color: #eefefd;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    font-size: 15px;
    color: #000;
    margin-top: 8px;
    height: 80px !important;
    resize: none;

}
.sweet-alert-container .swal2-html-container {
    margin: 1rem 2rem;
    font-size: 15px;
    text-align: left;
}
.sweet-alert-container .swal2-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 0;
    margin-top: 1.5rem;
}
.sweet-alert-container textarea:hover,
.sweet-alert-container textarea:focus,
.sweet-alert-container textarea:active{
    outline: 0 none;
    box-shadow: unset;
    background-color: #eefefd;
    border: 1px solid #CDCDCD;
}
.sweet-alert-container .swal2-styled {
    min-width: 100px;
    font-weight: 600;
}
.sweet-alert-container .form-group .swal2-textarea {
    margin: 0 !important;
    width: 100%;
}
.sweet-alert-container .form-group label {
    color: #000;
    font-weight: 600;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 0 !important;
}
/* order status start */
.order-status {
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 30px;
    margin-top: 24px;
}

.order-status li:not(:last-child) .partial {
    background: #c1c1c1;
}

.status-each span.partial {
    color: #fff !important;
    font-size: 10px !important;
    padding: 2px 5px;
    line-height: 13px !important;
    background-color: orange;
    border-color: #e69033;
    font-weight: 700 !important;
    border-radius: 4px;
    opacity: 1;
    display: inline-block;
}

.status-each {
    position: relative;
    padding-left: 20px;
    padding-bottom: 30px;
}

.status-each * {
    display: block;
}

.status-each strong {
    color: #7f7f7f;
}

.status-each.active strong {
    color: #000;
}

.status-each span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.7;
}

.status-each::before {
    position: absolute;
    left: -9px;
    top: 2px;
    content: "";
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #C4C4C4;
    border: 4px solid #EEFEFD;
    z-index: 1;
}

.status-each.active::before {
    background-color: #8F89F5;
}

.status-each::after {
    position: absolute;
    left: 0;
    top: 4px;
    content: "";
    height: 100%;
    width: 2px;
    background-color: #C4C4C4;
}

.order-status .status-each:last-child::after {
    opacity: 0;
}

.order-btn {
    border: solid 2px #ffac3d;
    padding: 0 5px;
    border-radius: 7px;
    color: #ffac3d;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.status-each em {
    display: inline-block;
    color: #8F89F5;
    margin: 4px;
}

.status-each em.bbb {
    color: #bbb;
}

.clock-msg {
    font-size: 2rem;
    padding: 10px;
    color: #777;
}

.status .btn.attachment-btn.mediaBtn {
    min-width: 48px;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 16%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.dashboard-form .reset-password-group .form-control {
    width: auto;
    background-color: transparent;
    border: medium none;
}

.reset-password-group .btn-success.btn-sm {
    border-radius: 8px;
    background-color: #D5FAC4;
    color: #40AF0C;
    border: 1px solid #D5FAC4;
    font-weight: 600;
}

.reset-password-group .btn-success.btn-sm:hover,
.reset-password-group .btn-success.btn-sm:active,
.reset-password-group .btn-success.btn-sm:focus {
    outline: 0 none;
    box-shadow: unset;
    background-color: #D5FAC4;
    color: #40AF0C;
    border: 1px solid #D5FAC4;

}

/* order status end */

.mediaBtn {
    height: 48px;
    position: relative;
}

.mediaBtn input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
}

@media screen and (max-width: 767.98px) {
    .settings-wrapper.common-wrapper {
        margin-top: 80px;
    }
}

@media screen and (max-width: 520px) {
    .campaign-control-content .title-main {
        font-size: 18px !important;
    }
}

.w120 {
    width: 120px;
}

.btn:hover {
    box-shadow: 1px 1px 6px #666;
}

.btn-placeorder {
    background: #a3e8a3;
    color: green;
}

.btn-dispensejob {
    background: #f3b9b4;
    color: #9e1403;
}

.btn-batch-review {
    background: #a3e8a3;
    color: green;
}

.btn-batch {
    background: #c5c5c5;
    color: #000000;
}

.btn-batch-process,
.btn-batch-process:hover {
    background-color: #8e88f4;
    border-color: #8a86cd;
    color: #fff;
}

.scrollbar-x {
    overflow-x: auto;
    padding-bottom: 10px;
}

.scrollbar-x::-webkit-scrollbar {
    height: 7px;
}

.scrollbar-x::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ccc;
}

.dropdown-menu.show {
    display: block;
    max-height: 285px;
    overflow-y: auto;
}

.no-carret .dropdown-toggle::after {
    display: none;
}

.trial-order-modal.common-modal .modal-header {
    justify-content: space-between;
}

.trial-order-modal .modal-header {
    padding-inline: 30px;
}

.trial-order-modal .modal-header .modal-title {
    position: relative;
    display: inline-block;
}

.trial-order-modal .modal-header .modal-title::after {
    position: absolute;
    left: calc(100% + 50px);
    content: "";
    height: 100%;
    width: 2px;
    background-color: #ddd;
    opacity: 0;
}

.trial-order-info .form-group label,
.trial-order-info .form-group span {
    display: block;
    color: #000
}

.trial-order-info .form-group+.form-group {
    margin-left: 50px;
}

.trial-order-info .form-group label {
    font-weight: 700;
    text-transform: capitalize;
}

.trial-order-info .form-group span {}

.trial-order-info .form-group:last-child span {
    color: #9582fc;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.checkbox-custom-group .custom-checkbox {
    position: absolute;
    opacity: 0;
}

.checkbox-custom-group .check-box-custom {
    display: inline-block;
    height: 34px;
    width: 34px;
    border: 1px solid #9582fc;
    border-radius: 4px;
}

.checkbox-custom-group .custom-checkbox:checked+.check-box-custom {
    background-color: #9582fc;
    background-image: url(assets/images/success-tick.png);
    background-repeat: no-repeat;
    background-position: center center;
}

input[type="checkbox"] {
    accent-color: #9582fc;
}

.loadMore {
    text-align: center;
    cursor: pointer;
    padding: 5px;
    background-color: #f6f6f6;
}
.mw-100{
    min-width: 100px;
}
.mw-120{
    min-width: 120px;
}
.mw-150{
    min-width: 150px;
}
.badge-btn{
    padding:8px;
}
.badge{
    background-color: #eee;
}
.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}
.badge-dark {
    color: #fff;
    background-color: #353535;
}
.badge-primary {
    color: #fff;
    background-color: #9582fc;
}
.badge-success, .badge-Successful {
    background-color: #CDFFCD;
    color:#00D000;
}
.badge-succeeded {
    background-color: #CDFFCD;
    color:#00D000;
}
.badge-paid {
    background-color: #CDFFCD;
    color:#00D000;
}
.badge-orange {
    color: #fff;
    background-color: orange;
}
.badge-info {
    color: #fff;
    background-color: #0d6892;
}
.badge-warning{
    background-color: #FDE6BD;
    color:#FFAF1C;
}
.badge-danger{
    background-color: #FFD4D4;
    color:#FF0000;
}

.badge-warning{
    background-color: #FDE6BD;
    color:#FFAF1C;
}
.badge-danger{
    background-color: #FFD4D4;
    color:#FF0000;
}
.badge-draft{
    background-color: #FDE6BD;
    color:#FFAF1C;
}
.badge-open {
    color: #fff;
    background-color: #9582fc;
}
.badge-paid {
    background-color: #CDFFCD;
    color:#00D000;
}
.badge-uncollectible{
    background-color: #FFD4D4;
    color:#FF0000;
}
.badge-void {
    color: #fff;
    background-color: #353535;
}
.badge-past_due {
    color: #fff;
    background-color:#FF0000;
}
.badge-past, .badge-expired, .badge-failed{
    color: #fff;
    background-color:#FF0000;
}
.badge-expired_soon , .badge-Expiring{
    color: black;
    background-color:#FFAF1C;
}
.badge-in_transit{
    color: #0d6efd;
    background-color: #CFE2FF;
}
.badge-0 {
    color: #fff;
    background-color: green;
}

.badge-1 {
    color: #fff;
    background-color: #569dd7;
}

.badge-2 {
    color: #fff;
    background-color: #ff0000;
}

.badge-3 {
    color: #fff;
    background-color: yellow;
}

.badge-4 {
    color: #fff;
    background-color: #ff0000;
}

.badge-Active {
    color: #fff;
    background-color: #6fbd6f;
}

.badge-Expired {
    color: #fff;
    background-color: #ff0000;
}

.badge-blue {
    color: #fff;
    background-color: #569dd7;
}

.badge-yellow {
    color: #555;
    background-color: yellow;
}

.badge-rev-w {
    min-width: 80px;
    text-transform: uppercase;
    padding-block: 6px;
}

.badge-rev-0 {
    color: #fff;
    background-color: #44b5f8;
}

.badge-rev-1 {
    color: #fff;
    background-color: #fa5f55;
}

.badge-rev-2 {
    color: #fff;
    background-color: orange;
}

.badge-rev-3 {
    color: #fff;
    background-color: #25a747;
}

.badge-rev-4 {
    color: #000;
    background-color: #ffe759;
}

.badge-rev-5 {
    color: #fff;
    background-color: #fa5f55;
    ;
}

.badge-rev-6 {
    color: #fff;
    background-color: #fa5f55;
}

.badge-rev-99 {
    color: #fff;
    background-color: #9582fc;
}

.orange-cross {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.btn2 {
    padding: 8px 13px;
    border-radius: 4px;
    display: inline-block;
}

.tableFixedHeader {
    max-height: 570px;
    overflow: auto;
}

.tableFixedHeader .table thead {
    background-color: #EEFEFD;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
}

.flex-btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 16px;
}

.flex-btn-group .form-control {
    background-color: #EEFEFD;
    border: 1px solid #CDCDCD;
    border-radius: 12px;
    padding-left: 35px;
    background-image: url(/assets/images/search-icon.png);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 10px;
    max-width: calc(100% - 54px);
}

.scan-btn {
    border: 1px solid #8F89F5;
    padding: 5px 6px;
    border-radius: 12px;
    background-color: #EEFEFD;
}

.scan-btn.inverse {
    background: #8e88f4;
}

.scan-btn.inverse img {
    filter: brightness(1.9);
}

.delete-card {
    background-color: #F0EEED;
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    color: #FA5C5C;
}

.v-middel tr th,
.v-middel tr td {
    vertical-align: middle;
}

.dashboard-form.quotes-form .calender-field {
    max-width: 145px;
}

.dashboard-form.quotes-form .p-calendar .p-inputtext {
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    border: 1px solid #CDCDCD;
    background-color: #eefefd;
    padding-right: 30px;
    background-image: url(/assets/images/date-icon.png);
    background-repeat: no-repeat;
    background-position: 94%;
    background-size: 20px;
    cursor: pointer;
}

.primary-text {
    color: #8F89F5;
}

.btn-create.btn {
    background-color: #8F89F5;
    color: #fff;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    padding: 8px 14px;
    text-transform: capitalize;
    min-width: 72px;
}

.btn.view-btn {
    background-color: #FFA907;
    color: #fff;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    padding: 8px 14px;
    text-transform: capitalize;
    min-width: 72px;
}

.primary-outline-btn.btn {
    border: 1px solid #8E88F4;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    padding: 4px;
}

.danger-outline-btn.btn {
    border: 1px solid #FF4040;
    height: 32px;
    width: 32px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.warning-outline-btn.btn {
    border: medium none;
    height: 32px;
    width: 32px;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.btn.danger-outline-btn {
    border: medium none;
    height: 32px;
    width: 32px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    border: 1px solid #fa5f55;
    color: #fa5f55;
}

.btn.danger-outline-btn:hover,
.btn.danger-outline-btn:active,
.btn.danger-outline-btn:focus {
    outline: 0 none;
    box-shadow: 1px 1px 6px #666;
    background-color: #FFF;
    color: #fa5f55;
}

.main-outline-btn {
    border: medium none;
    height: 32px;
    width: 32px;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

span.sucess-outline-btn.btn {
    border: medium none;
    height: 32px;
    width: 32px;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.light-bg-danger {
    background-color: #FFDDDD;
}

.shadow-circle {
    height: 12px;
    width: 12px;
    display: inline-block;
    background-color: #8F89F5;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 16px 0px #00000038;
}

.common-modal.create-quote-modal {
    width: 97%;
    max-width: 1100px;
}

.create-quote-modal .modal-body {
    padding-bottom: 0;
    padding-top: 0;
}

.title-orange {
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    padding-bottom: 15px;
    position: relative;
}

.title-orange::after {
    content: "";
    position: absolute;
    left: 0;
    height: 4px;
    width: 40px;
    background-color: #FFA907;
    bottom: 0;
}

.toggle-left {
    border-radius: 8px;
    overflow: hidden;
    background-color: #E6E5FC;
}

.toggle-left span {
    color: #8e88f4;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    padding: 8px 24px;
    display: inline-block;
    border-radius: 8px;
}

.toggle-left span.active {
    background-color: #8E88F4;
    color: #fff;
}

.osod-flag {
    border: 1px solid #8e88f4;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    box-shadow: 0px 6px 12px 0px #00000029;
    padding: 6px 15px;
    background-color: transparent;
}

button.osod-flag {
    padding: 2px 15px;
}

.toggle-right .osod-flag:hover,
.toggle-right .osod-flag:active,
.toggle-right .osod-flag:focus {
    outline: 0 none;

}

.create-quote-modal .dashboard-form .form-group .form-control {
    font-weight: normal;
}

.icon-group-currency span {
    position: absolute;
    right: 10px;
    top: 28px;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    opacity: 0.6;
}

.common-modal.softcl-modal {
    width: 100%;
    max-width: 530px;
}

.common-modal.price-quote-modal {
    max-width: 880px;
    width: 100%;
}

.create-quote-modal.price-quote-modal .modal-body {
    padding: 30px 35px 10px;
}

.softcl-modal .time-icon-control {
    background-image: url(/assets/images/time-grey.png);
    background-repeat: no-repeat;
    background-position: 95%;
    background-size: 15px;
}

.quote-title {
    font-size: 20px;
    color: #010101;
    font-weight: 700;
    line-height: 32px;
}

.patient-info-content p {
    font-size: 12px;
    color: #747474;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    max-width: 280px;
}

.patient-info-content img {
    width: 90%;
    max-width: 100px;
}

.lenses-both {
    padding: 14px 16px;
    border-radius: 12px;
    margin: 16px 0;
    background-color: #FFF;
    width: 100%;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 16px 0px #0000001F;
}

.lenses-table .logo-main {
    border-radius: 12px;
    height: 56px;
    width: 56px;
    padding: 6px;
    border: 1px solid #D6D6D6;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lenses-table .lens-title {
    color: #000;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.lenses-table .lens-info {
    color: #000;
    margin: 5px 0 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
}

.lenses-table .lens-label {
    color: #636363;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    display: block;
}

.lenses-table .lens-desc {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    display: block;
}

.lenses-table .lens-info .each-info {
    width: 100%;
    max-width: 33%;
    margin-top: 6px;
}

.pricing-table {
    border: 1px solid #F1F1F1;
    border-radius: 12px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.pricing-table.disabled {
    border: 1px solid #ccc;
    background-color: #F1F1F1;
}

.pricing-table .table-header-content {
    text-align: center;
    background-color: #8F89F5;
    border-radius: 12px;
    padding: 8px;
    width: calc(100% - 30px);
    margin-inline: auto;
    margin-top: -24px;
    line-height: 12px;
}

.pricing-table .table-header-content h6,
.pricing-table .table-header-content p {
    color: #fff;
    margin: 0;
}

.pricing-table .table-header-content h6 {
    font-weight: 700;
    font-size: 14px;
}

.pricing-table .table-header-content p {
    font-weight: 500;
    font-size: 11px;
}

.pricing-table .table-header-content small {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
}

.table-main-content {
    padding: 16px 16px 8px 16px;
}

.table-main-content p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    margin: 0;
}

.table-footer-content {
    background-color: #F6F5FF;
    padding: 12px 16px;
    border-radius: 0 0 12px 12px;
}

.table-footer-content p {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    line-height: 24px;
}

.net-price-text {
    border-top: 1px solid #ddd;
    padding-top: 8px;
    margin-top: 8px;
}



.pricing-table.yearly-pricing {
    /* background-color: #EEFEFD; */
}

.pricing-table.disabled .table-header-content h6,
.pricing-table.disabled .table-header-content small {
    color: #000;
}

.pricing-table.yearly-pricing .table-footer-content.bg-green {
    background-color: #AEF8AE;
}

.footer-quotes-title {
    font-size: 16px;
    line-height: 32px;
    color: #010101;
    font-weight: 700;
    margin-bottom: 0;
}

.quotes-footer p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #010101;
    margin-bottom: 8px;
}

.contact-footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;
}

.contact-footer ul::after {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    background-color: #ccc;
}

.contact-footer ul li {
    width: 100%;
    max-width: 50%;
    text-align: center;
}

.contact-footer ul li {
    /* width: 100%; */
    /* max-width: 33.33%; */
}

.contact-footer ul li a {
    padding: 0 16px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
}

.contact-footer ul li a span {
    padding-left: 16px;
}

.contact-footer ul li img {
    max-width: 24px;
}

.info-section {
    width: calc(100% - 70px);
    padding-left: 12px;
    position: relative;
}

.top-section {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 575.98px) {
    .contact-footer ul {
        padding: 0px 0 10px;
        flex-wrap: wrap;
    }

    .contact-footer ul::after {
        display: none;
    }

    .contact-footer ul li {
        max-width: 100%;
    }

    .contact-footer ul li+li {
        margin-top: 15px;
    }
}

.create-quote-modal .btn-close,
.soft-quote-modal .btn-close {
    font-size: 0px;
    opacity: 1;
    margin: 0;
    position: absolute;
    padding: 0;
    height: 24px;
    width: 24px;
    right: -7px;
    top: -10px;
    z-index: 1;
}

.create-quote-modal .btn-close:hover,
.soft-quote-modal .btn-close:hover,
.create-quote-modal .btn-close:active,
.soft-quote-modal .btn-close:active,
.create-quote-modal .btn-close:focus,
.soft-quote-modal .btn-close:focus {
    outline: 0 none;
    box-shadow: unset;
}

.create-quote-modal .btn-close img,
.soft-quote-modal .btn-close img {
    width: 24px;
    height: 24px;
}

.oley {
    position: relative;
    z-index: 2;
}

.oley .modal-body {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
}

.oley .detail-header {
    border-radius: 10px 10px 0 0 !important;
}

.oley::after {
    position: fixed;
    content: "";
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #010101c4;
    z-index: -1;
}

.soft-quote-modal.common-modal .modal-content,
.create-quote-modal.common-modal .modal-content {
    overflow: visible;
}

.office-select-group .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.office-select-group .dropdown-menu li img {
    width: 40px;
    margin-right: 10px;
    height: 40px;
    border-radius: 50%;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #8e88f4;
}

.create-quote-modal.common-modal .modal-footer .btn {
    padding: 8px 14px;
}

.common-modal .modal-footer .btn {
    padding: 8px 14px;
}

.btn.autotrial {
    color: #9582fc;
    border: solid thin #9582fc;
    border-radius: 20px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #fff;
    background: #8e88f4;
}

.create-quote-modal .dropdown-menu {
    width: 100%;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    text-align: center;
    outline: 0 none;
}

.p-datepicker-header .p-datepicker-decade {
    white-space: nowrap;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #fff;
    background: #8e88f4;
}

.create-quote-modal .dropdown-menu {
    width: 100%;
}

.calender-field .p-calendar .p-datepicker {
    min-width: 180px;
}

.calender-field .p-link:focus,
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.p-datepicker table td>span.p-ripple:hover,
.p-datepicker table td>span.p-ripple:active,
.p-datepicker table td>span.p-ripple:focus {
    outline: 0 none;
    box-shadow: unset;
    box-shadow: unset;
}

.powered-by p img {
    max-width: 70px;
    margin-left: 15px;
}

.powered-by p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
}

.create-quote-modal .dropdown-item:hover,
.create-quote-modal .dropdown-item:active,
.create-quote-modal .dropdown-item:focus {
    color: #fff;
    background-color: #9581fb;
}

.create-quote-modal .patient-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0px;
}

.create-quote-modal .patient-info-content p {
    max-width: 340px;
}


@media print {
    .pdf-md-4 {
        max-width: 33.33%;
    }

    .table-row .pdf-md-4:first-child {
        padding-right: 0;
    }

    .table-row .pdf-md-4:nth-child(2) {
        padding: 0 8px;
    }

    .table-row .pdf-md-4:nth-child(3) {
        padding-left: 0;
    }

    .tollfree-wrap {
        margin: 0 15px;
        max-width: calc(100% - 30px);
    }

    .row.px-0.mt-4 {
        margin-left: 15px;
        margin-right: 15px;
        max-width: calc(100% - 30px);
    }

    .table-footer-content p {
        font-size: 11px !important;
        line-height: 18px !important;
    }

    .tollfree-wrap {
        padding: 10px 16px !important;
    }

    .table-footer-content {
        padding: 12px 10px !important;
    }

    .table-main-content {
        padding: 16px 10px 8px !important;
    }

    .pdf-md-5 {
        max-width: 41.66%;
    }

    .pdf-md-6 {
        max-width: 50%;
    }

    .pdf-md-7 {
        max-width: 58.33%;
    }

    .pdf-md-8 {
        max-width: 66.66%;
    }

    .btn-close {
        display: none;
    }

    .pricing-table .table-header-content {
        /* background-color: #8f89f5; */
        /* print-color-adjust: exact; */
    }

    .table-footer-content {
        /* background-color: #f6f5ff; */
        print-color-adjust: exact;
    }

    .pricing-table.disabled .table-header-content {
        border: 1px solid #ccc !important;
    }

    .pricing-table.disabled .table-footer-content,
    .pricing-table.disabled .table-header-content {
        background-color: #f1f1f1 !important;
    }

    .pricing-table.yearly-pricing .table-footer-content {
        background-color: #aef8ae;
        print-color-adjust: exact;
    }

    .pricing-table.yearly-pricing {
        background-color: #eefefd;
        print-color-adjust: exact;
    }

    .text-md-end .patient-info-content {
        text-align: right;
    }

    .col-md-4.pdf-md-4.text-md-end.mt-md-0.mt-4,
    .col-md-3.text-md-end.mt-md-0.mt-4.pe-md-0 {
        margin-top: 0 !important;
    }

    .tollfree-wrap {
        padding: 0 16px;
    }

    .tollfree-wrap .col-md-4.mt-md-0.mt-4 {
        max-width: 33.33%;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: 8px !important;
        margin-bottom: 8px;
    }

    .tollfree-wrap .col-md-4.mt-md-0.mt-4:last-child,
    .tollfree-wrap .col-md-4.mt-md-0.mt-4:nth-child(2) {
        border-left: 1px solid #f1f1f1;
    }

    .create-quote-modal.price-quote-modal .modal-body {
        padding: 10px 15px !important;
    }

    .pricing-table {
        margin-top: 25px;
    }

    .table-main-content p {
        line-height: 15px;
    }

    .table-footer-content {
        padding: 8px 16px;
    }

    .table-main-content {
        padding: 8px 16px;
    }

    .tollfree-wrap {
        padding: 8px 12px;
    }

    .contact-footer ul {
        padding: 4px 0 !important;
    }

    .powered-by p {
        padding: 0px 0 8px !important;
    }

    .row.px-0.mt-4 {
        margin-top: 10px !important;
    }

    .tollfree-wrap {
        padding: 4px 16px !important;
    }

    .table-footer-content p {
        font-size: 12px;
        line-height: 16px;
    }

    .patient-info-content img {
        width: 90%;
        max-width: 60px !important;
        max-height: 60px !important;
    }

    .lenses-table .lens-info .each-info {
        margin-top: 2px;
    }

    .lenses-table .lens-label {
        font-size: 12px;
        line-height: 16px;
    }

    .lenses-table .lens-desc {
        font-size: 12px;
        line-height: 14px;
    }

    .lenses-table .logo-main {
        height: 40px;
        width: 40px;
    }

    .lenses-table .logo-main img {
        width: auto;
        max-width: 100%;
    }

    .quotes-footer p {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 4px;
    }

    .row.px-0.mt-4 .col-md-9 {
        width: 75%;
        padding-right: 0;
    }

    .row.px-0.mt-4 .col-md-3 {
        width: 25%;
        padding-left: 0;
    }

    .col-md-6.ps-md-0.pdf-md-7.text-md-end.mt-md-0.mt-4 .patient-info-content .patient-info-content p {
        position: relative;
        right: -10px;
    }

    .table-footer-content p {
        font-size: 12px;
    }

    .tollfree-wrap {
        /* border: 1px solid #F1F1F1; */
    }

    .text-print-end {
        text-align: right;
    }

    .col-md-6.pdf-md-6.text-md-start {
        margin-top: 24px !important;
    }


}


.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button,
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #8f89f5;
}

.cursor-auto {
    cursor: auto;
}

.swal2-input-label {
    font-size: 24px;
    font-weight: bold;
}


.quotes .store-selection select,
.existing-job-location .store-selection select,
.status .store-selection select {
    height: 40px;
}

.quotes .store-selection .select,
.existing-job-location .store-selection .select,
.status .store-selection .select {
    height: 40px;
}

.store-selection .form-control:hover {
    box-shadow: none;
}

.store-selection .form-control {
    background-image: url(/assets/images/dropdown-arrow-grey.svg);
    background-repeat: no-repeat;
    background-size: 15px;
    padding-right: 30px;
    white-space: nowrap;
    background-position: right 8px center;
    line-height: 26px;
    border: 1px solid #F1F1F1;
    background-size: 12px;
}
.store-selection .btn{
    padding-right:30px;
}

.store-selection.single .form-control {
    color: #aaa;
    background-image: none;
}
.webstore-status-flags .p-element.userRole {
    top: -2px;
}
.quotes .store-selection:after,
.existing-job-location .store-selection:after,
.status .store-selection:after {
    top: 40%;
}

.btn-input {
    border-radius: 8px;
    border: 1px solid #CDCDCD;
    background-color: #eefefd;
    height: 48px;
}

.select-dropdown {
    position: relative;
}

.select-dropdown .form-control:hover,
.select-dropdown .form-control:active,
.select-dropdown .form-control:focus {
    outline: 0 none;
    box-shadow: unset;
}

.detail-content.scroll {
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.status .store-selection:after {
    top: 40%;
}

.white-table-data .table-responsive {
    padding: 0;
    background-color: #fff;
}

.white-table-data tbody tr,
.white-table-data tbody tr td {
    background-color: #fff;
    border: medium none;
}

.white-table-data tbody tr:hover {
    box-shadow: unset;
    border-radius: 0;
}

.open-ticket-notification{
    max-width: 650px;
}

.search {
    padding-left: 35px;
    background-image: url(/assets/images/search-icon.png);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 10px;
}

.no-icon{
    background-color: #eee !important;
}

.no-icon::after {
    display: none !important; 
  }